.Profile {
  text-align: center;
}

.Profile-logo {
  height: 25vmin;
  border-radius: 50%;
}

.Profile-header {
  background-color: #ffffff;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

}

