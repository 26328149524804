.container{
    margin-left: auto;
    margin-right:auto;
   
}

.card{
    color: black;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.card:hover{
    color: orange;
}
.btn-primary-spacing{
    display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.button-container{
    display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

img {
    float: left;
    width:  275px;
    height: 275px;
    object-fit: cover;
}
iframe{
    display: flex;
    align-items: center;
    justify-content: center;
}

  